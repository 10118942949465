<template>
  <div class="">
    <BModal v-model="modal" title="員工基本資訊">
      <table class="table table-hover">
        <thead class="thead-light">
        <tr>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="text-left">
            姓名
          </td>
          <td class="text-left">
            Jia-We Hsu
          </td>
        </tr>
        <tr>
          <td class="text-left">
            Email
          </td>
          <td class="text-left">
            Jia-WeHsu@taiwanmobile.com
          </td>
        </tr>
        <tr>
          <td class="text-left">
            電話
          </td>
          <td class="text-left">
            09350935xxxxx
          </td>
        </tr>

        </tbody>
      </table>
      <h1>過去案件</h1>
      <table class="table table-hover">
        <thead class="thead-light">
        <tr>
          <th>說明</th>
          <th>狀態</th>
          <th>時間</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="text-left">
            電腦故障
          </td>
          <td class="text-center">
            已結案
          </td>
          <td class="text-center">
            2023-10-10 10:10:10
          </td>
        </tr>

        </tbody>
      </table>
    </BModal>
  </div>
</template>

<script setup lang="ts">
import {onMounted, ref,defineExpose} from "vue";
import {BModal} from "bootstrap-vue-next";

//======props======
// const props = defineProps({
//   propsValue: String,
// })

//======ref======
const year = ref<number>(2020)
const modal = ref(false)

// interface Book {
//   title: string
//   year?: number
// }
//
// const book: Book = reactive({ title: 'Vue 3 Guide' })

//======emit======
// const emit = defineEmits<{
//   returnOk: [value: string]
// }>()
// const emit = defineEmits<{
//   (e: 'returnOk', value: string): void
// }>()

//======method======
function loadData() {
  //this.datas=await apiUBossShopBk.api_Order_ListQuery(null);
}

function showModal() {
  modal.value=true;
}

function hideModal() {
  modal.value=false;
}

function toggleModal() {
  modal.value=!modal.value;
}

function buttonClick(event: Event, sValue: string) {
  // console.log("sValue:" + sValue);
}

//======life cycle======
onMounted(() => {
  loadData();
});

defineExpose ({ showModal })
</script>

<style scoped lang="scss">

</style>
