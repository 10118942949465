<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page"><router-link :to="{ name: 'IssueList' }">首頁</router-link> </li>
          </ol>
        </nav>
        <div class="panelChat">
          <h1>M+ 對話</h1>
<!--          <form>-->
            <div class="form-group row">
              <label for="staticEmail" class="col-sm-2 col-form-label">使用者:<a href="#" @click="showDialogMemberInfo()">
                Jia-We Hsu</a></label>
              <div class="col-sm-10">
                <!--              <input type="text" readonly class="form-control-plaintext" id="staticEmail" value="email@example.com">-->
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword" class="col-sm-2 col-form-label">對話</label>
              <div class="col-sm-10">
                <table class="table table-hover">
                  <thead class="thead-light">
                  <tr>
                    <th class="text-center">角色</th>
                    <th>訊息</th>
                    <th>時間</th>
                    <th>功能</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="text-left">
                      <a href="#" @click="showDialogMemberInfo()"> Jia-We Hsu</a>
                    </td>
                    <td class="text-left">
                      我是嘉義民雄
                    </td>
                    <td class="text-left">
                      2023-10-10 10:10:10
                    </td>
                    <td class="text-center">
                      <button class="btn btn-primary btnF">引用</button>
                      <a class="btn btn-primary btnF" href="#"><i class="fa fa-plus-circle"></i> 建立新案</a>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">
                      <a href="#" @click="showDialogMemberInfo()"> Jia-We Hsu</a>
                    </td>
                    <td class="text-left">
                      我們排班系統進不去耶!!
                    </td>
                    <td class="text-left">
                      2023-10-10 10:10:10
                    </td>
                    <td class="text-center">
                      <button class="btn btn-primary btnF">引用</button>
                      <a class="btn btn-primary btnF" href="#"><i class="fa fa-plus-circle"></i> 建立新案</a>
                    </td>
                  </tr>
                  <tr class="table-primary">
                    <td class="text-left">
                      <a href="#" @click="showDialogMemberInfo()"> 400 (Eng1)</a>
                    </td>
                    <td class="text-left">
                      只有排班管理系統不行嗎?!!
                    </td>
                    <td class="text-left">
                      2023-10-10 11:10:10
                    </td>
                    <td class="text-center">
                      <button class="btn btn-primary btnF">引用</button>
                      <a class="btn btn-primary btnF" href="#"><i class="fa fa-plus-circle"></i> 建立新案</a>
                    </td>
                  </tr>

                  <tr>
                    <td class="text-left">
                      <a href="#" @click="showDialogMemberInfo()"> Jia-We Hsu</a>
                    </td>
                    <td class="text-left">
                      是的
                    </td>
                    <td class="text-left">
                      2023-10-10 11:15:10
                    </td>
                    <td class="text-center">
                      <button class="btn btn-primary btnF">引用</button>
                      <a class="btn btn-primary btnF" href="#"><i class="fa fa-plus-circle"></i> 建立新案</a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword" class="col-sm-2 col-form-label">回應</label>
              <div class="col-sm-8">

                <textarea class="form-control" rows="3" v-model="msgToReply"></textarea>
                <button class="btn btn-primary">送出</button>
              </div>
              <div class="col-sm-2">
                <button class="btn btn-primary btnF" @click="btnGPT">GPT建議</button>
                <button class="btn btn-primary btnF" @click="btnMsgTemplate">訊息範本</button>
              </div>
            </div>
<!--          </form>-->
        </div>

        <hr>
        <div class="panelCase">
          <h1>案件歸檔</h1>
<!--          <form>-->
            <div class="form-group row">
              <label for="staticEmail" class="col-sm-2 col-form-label">說明</label>
              <div class="col-sm-10">
                <textarea class="form-control" rows="3" v-model="msgToCase"></textarea>
                <button class="btn btn-primary btnF" @click="btnGPTSummary">GPT總結案件</button>
              </div>
            </div>

            <div class="form-group row">
              <label for="staticEmail" class="col-sm-2 col-form-label">類型</label>
              <div class="col-sm-10">
                <select class="form-control">
                  <option>操作問題</option>
                  <option>電腦故障</option>
                  <option>其他</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="staticEmail" class="col-sm-2 col-form-label">類型</label>
              <div class="col-sm-10">
                <label class="custom-control custom-checkbox" style="display: flex">
                  <input type="checkbox" class="custom-control-input" checked="">
                  <div class="custom-control-label">加入知識庫 (未來對特定範圍內的明確問題，GPT可以直接回答) </div>
                </label>
              </div>
            </div>
            <div class="form-group row">
              <label for="staticEmail" class="col-sm-2 col-form-label"></label>
              <div class="col-sm-10">
                <button class="btn btn-primary">送出</button>
              </div>
            </div>


<!--          </form>-->
        </div>
      </div>
    </div>

    <MemberInfoDialog ref="memberInfoDialog"></MemberInfoDialog>
    <TemplateDialog ref="templateDialog"></TemplateDialog>
  </div>
</template>

<script setup lang="ts">
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import MemberInfoDialog from "@/views/MemberInfoDialog.vue";
import TemplateDialog from "@/views/TemplateDialog.vue";

const route = useRoute();

//======ref======
const Id = ref<string>("")
const year = ref<number>(2020)
const msgToReply = ref<string>("")
const msgToCase = ref<string>("")
const memberInfoDialog = ref(null)
const templateDialog = ref(null)
// interface Book {
//   title: string
//   year?: number
// }
//
// const book: Book = reactive({ title: 'Vue 3 Guide' })


//======method======
function loadData() {
  //this.datas=await apiUBossShopBk.api_Order_ListQuery(null);
}

function buttonClick(event: Event, sValue: string) {
  console.log("sValue:" + sValue);
  //emit("change", 1)
}

function showDialogMemberInfo() {
  //show dialog
  if (memberInfoDialog.value) {
    memberInfoDialog.value.showModal();
  }

}

function btnGPT(){
  msgToReply.value="xxxxxxxxxxxxxxxx由GPT產生xxxxxxxxxxxxxxxx ";
}

function btnMsgTemplate(){
  if (templateDialog.value){
    templateDialog.value.showModal();
  }
}

function btnGPTSummary(){
  msgToCase.value="xxxxxxxxxxxxxxxx由GPT 分析對話後，給予摘要 xxxxxxxxxxxxxxxx ";
}
//======life cycle======
onMounted(() => {
  Id.value = route.query.Id + "";
  loadData();
});


</script>

<style scoped lang="scss">
.btnF {
  margin: 5px;
}

.row400{
  background-color: lightgoldenrodyellow;
}
.panelChat{
  background-color: lightgreen;
}

.panelCase{
  background-color: cadetblue;
}
</style>
