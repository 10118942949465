<template>
  <div style="max-width: 1800px">
    <h1>IssueList</h1>

    篩選
    <button class="btn btn-primary" >尚未處理</button>
    <button class="btn btn-primary" >處理中</button>
    <button class="btn btn-primary" >全部</button>

    <table class="table table-hover">
      <thead class="thead-light">
      <tr>
        <th class="text-center">功能</th>
        <th>使用者</th>
        <th>說明</th>
        <th>狀態</th>
        <th>建立時間</th>
        <th>最後回應時間</th>
        <th>負責工程師</th>
        <th>優先</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>
          <button class="btn btn-primary" @click="gotoDetail('1')">詳細</button>
        </td>
        <td >Jia-We Hsu
        </td>
        <td class="text-left">
        </td>
        <td class="text-center">
          <button type="button" class="btn btn-primary position-relative">
            待處理
            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            5+
            <span class="visually-hidden">unread messages</span>
          </span>
          </button>
        </td>
        <td class="text-center">
          1天前
        </td>
        <td class="text-center">
          33分鐘前
        </td>
        <td class="text-center">
          400工程師A
        </td>

        <td class="text-center">
          <a class="btn btn-primary" href="#"><i class="fa fa-star"></i> </a>
        </td>


      </tr>
      <tr>
        <td>
          <button class="btn btn-primary" @click="gotoDetail('1')">詳細</button>
        </td>
        <td >Nick Huang
        </td>
        <td class="text-left">
        </td>
        <td class="text-center">
          <button type="button" class="btn btn-warning position-relative">
            處理中
            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            2+
            <span class="visually-hidden">unread messages</span>
          </span>
          </button>
        </td>
        <td class="text-center">
          2天前
        </td>
        <td class="text-center">
          33分鐘前
        </td>
        <td class="text-center">
          400工程師A
        </td>

        <td class="text-center">
          <a class="btn btn-primary" href="#"><i class="fa fa-star"></i> </a>
        </td>


      </tr>
      <tr>
        <td>
          <button class="btn btn-primary" @click="gotoDetail('2')">詳細</button>
        </td>
        <td >Ling Zhan
        </td>
        <td class="text-left">
          重設OS密碼
        </td>
        <td class="text-center">
          <button type="button" class="btn btn-success position-relative">
            完成
<!--            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">-->
<!--    0+-->
<!--    <span class="visually-hidden">unread messages</span>-->
<!--  </span>-->
          </button>
        </td>
        <td class="text-center">
          3天前
        </td>
        <td class="text-center">
          2天前
        </td>
        <td class="text-center">
          400工程師A
        </td>

        <td class="text-center">
          <a class="btn btn-primary" href="#"><i class="far fa-solid fa-star"></i> </a>
        </td>


      </tr>

      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import {onMounted, ref} from "vue";
import {useRouter} from "vue-router";
const router= useRouter();

//======ref======
const year = ref<number>(2020)
// interface Book {
//   title: string
//   year?: number
// }
//
// const book: Book = reactive({ title: 'Vue 3 Guide' })


//======method======
function loadData() {
  //this.datas=await apiUBossShopBk.api_Order_ListQuery(null);
}

function buttonClick(event: Event, sValue: string) {
  console.log("sValue:" + sValue);
  //emit("change", 1)
}
function gotoDetail(Id: string) {
  //got to page detail
  router.push({ name: 'IssueDetail', query: { Id: Id }})
}

//======life cycle======
onMounted(() => {
  loadData();
});


</script>

<style scoped lang="scss">

</style>
