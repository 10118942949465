<template>
  <div class="">
    <BModal v-model="modal" title="訊息範本">
      <table class="table table-hover">
        <thead class="thead-light">
        <tr>
          <td>訊息</td>
          <td>功能</td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="text-left">
            xxxxxxxxxxxxxxxx
          </td>
          <td class="text-left">
            <button class="btn btn-primary" >選用</button>
          </td>
        </tr>


        </tbody>
      </table>
    </BModal>
  </div>
</template>

<script setup lang="ts">
import {onMounted, ref,defineExpose} from "vue";
import {BModal} from "bootstrap-vue-next";

//======props======
// const props = defineProps({
//   propsValue: String,
// })

//======ref======
const year = ref<number>(2020)
const modal = ref(false)

// interface Book {
//   title: string
//   year?: number
// }
//
// const book: Book = reactive({ title: 'Vue 3 Guide' })

//======emit======
// const emit = defineEmits<{
//   returnOk: [value: string]
// }>()
// const emit = defineEmits<{
//   (e: 'returnOk', value: string): void
// }>()

//======method======
function loadData() {
  //this.datas=await apiUBossShopBk.api_Order_ListQuery(null);
}

function showModal() {
  modal.value=true;
}

function hideModal() {
  modal.value=false;
}

function toggleModal() {
  modal.value=!modal.value;
}

function buttonClick(event: Event, sValue: string) {
  // console.log("sValue:" + sValue);
}

//======life cycle======
onMounted(() => {
  loadData();
});

defineExpose ({ showModal })
</script>

<style scoped lang="scss">

</style>
